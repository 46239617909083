




















import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: "info-circle",
    },
    size: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
    },
  },
  setup() {
    const target = ref(null as HTMLElement | null);
    return {
      target,
    };
  },
});
