










































import useCancelButton from "@/composables/buttons/useCancelButton";
import useDeleteButton from "@/composables/buttons/useDeleteButton";
import IButton from "@/interfaces/IButton";
import { sameAs } from "@/utilities/validators";
import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import InputGroup from "./InputGroup.vue";
import Popover, { PopoverProps } from "./Popover.vue";
import useEscapeHtml from "@/utilities/useEscapeHtml";

export default defineComponent({
  components: {
    Popover,
    InputGroup,
  },
  emits: ["confirmedDeletion"],
  props: {
    ...PopoverProps,
  },
  setup(_, { root, emit }) {
    const confirmationNameLimitedCharaters = 15;
    const show = ref(false);
    const entityName = ref("");
    const confirmName = ref("");
    const confirmNameGoal = ref("");
    var primaryClick: (() => void) | null = null;
    var secondaryClick: (() => void) | null = null;

    const v$ = useVuelidate(
      useValidations(confirmNameGoal),
      {
        confirmName: confirmName,
      },
      { $autoDirty: true, $stopPropagation: true }
    );

    function confirmDelete(
      mEntityName: string,
      confirmationName: string | null
    ): Promise<boolean> {
      show.value = true;
      entityName.value = mEntityName;

      // only get first 15 charaters of confirmation name
      confirmNameGoal.value =
        confirmationName
          ?.substring(0, confirmationNameLimitedCharaters)
          .trim() ?? "";

      confirmName.value = "";
      return new Promise(function (resolve, _) {
        primaryClick = () => {
          resolve(true);
          show.value = false;
        };

        secondaryClick = () => {
          resolve(false);
          show.value = false;
        };
      });
    }

    watch(
      () => show.value,
      (newValue) => {
        if (!newValue) v$.value.$reset();
      }
    );

    return {
      useEscapeHtml,
      entityName,
      confirmName,
      confirmNameGoal,
      confirmDelete,
      show,
      primaryButton: computed<IButton>(() => {
        return {
          ...useDeleteButton(async () => {
            if ((await v$.value.$validate()) && primaryClick != null)
              primaryClick();
          }),
          disabled: v$.value.$error || v$.value.$invalid,
        };
      }),
      v$,
      secondaryButton: useCancelButton(() => (show.value = false)),
    };
  },
});

function useValidations(confirmName: Ref<string | null>) {
  return {
    confirmName: {
      sameAs: sameAs(confirmName),
    },
  };
}
