import useProfile from "./useProfile";
import {
  Application,
  Maybe,
  Organization,
  Role,
  User,
  UserRoleAssignment,
} from "@/graphql/types";

interface Entities {
  Organization: Organization;
  Role: Role;
  Application: Application;
  User: User | UserRoleAssignment;
}

/**
 * Returns the calculated result of user being able to navigate to the wanted entity.
 *
 * All objects must have ID
 *
 * If key is 'Role' the role object must include its parent application where ownerOrganization is not NULL
 *
 * if key is 'User' and object is
 *
 * @param {K extends keyof Entities} key
 * @param {Maybe<Entities[K]>} obj
 * @returns {boolean}
 */
export default function <K extends keyof Entities>(
  key: K,
  obj: Maybe<Entities[K]>
) {
  const {
    isCentralAdmin,
    isSupportAdmin,
    isLocalAdmin,
    isAppAdmin,
    isHomeOrg,
    homeOrgAssigneeRoles,
    homeOrgApplications,
    applicationAssignments,
  } = useProfile();

  function canNavigateToOrganization(obj: Entities["Organization"]) {
    // If user is local admin, she can only access own organization
    if (isLocalAdmin.value && isHomeOrg(obj?.id)) return true;

    return false;
  }

  function canNavigateToRole(obj: Entities["Role"]) {
    if (isLocalAdmin.value) {
      if (obj.application?.ownerOrganization == null) {
        // Is user is local admin, and role application belongs to home org
        console.warn(
          "Role must include application.ownerOrganization to propely determine if user can access it"
        );
      }

      if (isHomeOrg(obj.application?.ownerOrganization?.id)) return true;

      // If user is local admin, she can only access roles that home org have access too.
      if (homeOrgAssigneeRoles.value.some((element) => element?.id == obj?.id))
        return true;
    }

    // If user is app admin, she can only access roles that home org have access too and related applications are assigned to her
    if (
      isAppAdmin.value &&
      homeOrgAssigneeRoles.value.some((element) => element?.id == obj?.id) &&
      applicationAssignments.value.some(
        (app) => app?.id == obj?.application?.id
      )
    )
      return true;

    return false;
  }

  function canNavigateToApplication(obj: Entities["Application"]) {
    // If user is local admin, she can only access applications that home org have implicit access too.
    if (
      isLocalAdmin.value &&
      homeOrgApplications.value.some((element) => element?.id == obj?.id)
    )
      return true;

    // If user is app admin, she can only access applications are assigned to her
    if (
      isAppAdmin.value &&
      applicationAssignments.value.some((app) => app?.id == obj?.id)
    )
      return true;

    return false;
  }

  function canNavigateToUser(obj: Entities["User"]): boolean {
    // if local admin, and obj is user,  she can only access users that belong to same org.
    if (isLocalAdmin.value && isUser(obj)) {
      if (obj.organization == null)
        console.warn(
          "User must include .organization to propely determine if logged in user can access it"
        );
      return isHomeOrg(obj.organization);
    }

    // If local admin && obj is UserRoleAssignment we need to check that obo organization is homeorg or that user is from homeorg
    if (isLocalAdmin.value && isRoleAssignment(obj)) {
      if (obj.organization == null && obj.user?.organization == null)
        console.warn(
          "RoleAssignment must include either assignee org (.organization) or .user.organization (assigned user homeorg) in order to proberly determinde if logged in user can access"
        );
      if (isHomeOrg(obj.organization) || isHomeOrg(obj.user?.organization))
        return true;
      return false;
    }

    // If local admin && obj is UserRoleAssignment we need to check that obo organization is homeorg or that user is from homeorg
    if (isAppAdmin.value && isRoleAssignment(obj)) {
      if (obj.organization == null && obj.user?.organization == null)
        console.warn(
          "RoleAssignment must include either assignee org (.organization) or .user.organization (assigned user homeorg) in order to proberly determinde if logged in user can access"
        );

      if (!isHomeOrg(obj.organization) && !isHomeOrg(obj.user?.organization))
        return false;

      // If user is app admin, she can only access applications are assigned to her
      if (obj.role == null)
        console.warn(
          "RoleAssignment must include .role in order to proberly determinde if logged in user can access"
        );

      const foundRole = homeOrgAssigneeRoles.value.find(
        (role) => role?.id == obj?.role?.id
      );
      if (
        foundRole != null &&
        homeOrgApplications.value.some(
          (app) => app?.id == foundRole.application?.id
        )
      ) {
        return true;
      }

      return false;
    }
    return false;
  }

  if (isCentralAdmin.value || isSupportAdmin.value) return true;
  switch (key) {
    case "Organization":
      return canNavigateToOrganization(obj as Entities["Organization"]);
    case "Role":
      return canNavigateToRole(obj as Entities["Role"]);
    case "Application":
      return canNavigateToApplication(obj as Entities["Application"]);
    case "User":
      return canNavigateToUser(obj as Entities["User"]);
  }

  return false;
}

function isRoleAssignment(
  value: Entities["User"]
): value is UserRoleAssignment {
  if (value.__typename == "UserRoleAssignment") {
    return true;
  } else {
    return false;
  }
}

function isUser(value: Entities["User"]): value is User {
  return value.__typename == "User";
}
