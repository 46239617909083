import get from "@/utilities/get";
import isObject from "@/utilities/isObject";

export interface WithFilter {
  filter: string;
  key: string;
}

export default function (object: any, filter: WithFilter[]) {
  let isValid = true;

  filter.forEach((field) => {
    if (field.filter == "" || isValid == false) return;
    let value: string;

    if (isObject(object)) {
      value = get(object, field.key);
    } else {
      value = object[field.key];
    }

    if (
      (value != undefined || value != null) &&
      !value.toLowerCase().includes(field.filter.toLowerCase())
    ) {
      isValid = false;
    }
  });
  return isValid;
}
