



























































































































































import { TableField } from "@/types/TableField";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { BvTableField } from "bootstrap-vue";
import Error from "./Error.vue";
import useLowercased from "@/composables/useLowercased";
import Spinner from "./Spinner.vue";
import IMenu from "@/interfaces/IMenu";
import Dropdown from "./Dropdown.vue";
import { ApolloError } from "@apollo/client";
import useFilter, { WithFilter } from "@/composables/useFilter";
import { useNumberFormat } from "@/composables/useNumberFormat";
import useScrollToElement from "@/composables/useScrollToElement";

export default defineComponent({
  emits: ["clicked", "filtersChanged", "sortBy", "sortDesc"],
  components: { Error, Spinner, Dropdown },
  props: {
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array as PropType<Readonly<unknown[]>>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: false,
    },
    fields: {
      type: Array as PropType<TableField[]>,
      default: [],
    },
    options: {
      type: Array as PropType<IMenu[]>,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    tdClass: {
      type: String,
      default: "align-middle",
    },
    lite: {
      type: Boolean,
      default: false,
    },
    // When data is loading from scratch
    isLoading: {
      type: Boolean,
      default: false,
    },
    // When busy updating values, eg on filters input
    isBusy: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    sortCompareLocale: {
      type: String,
      default: "da",
    },
    perPage: {
      type: Number,
      default: 50,
    },
    currentPage: {
      type: Number,
      required: false,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object as PropType<ApolloError>,
      required: false,
    },
    customFilterFunction: {
      type: Function,
      required: false,
    },
    disableLocalSort: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    tableClass: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    //#region Component refs
    // Filters containing each b-input value paired with its filter key.
    const filters = ref([] as WithFilter[]);
    const sortBy = ref(props.fields[0].key as string | null);
    const mSortDesc = ref(props.sortDesc);
    // Keeps track of the current page, when paging is local
    const tablePage = ref(1);
    // Keeps track of the count returned by local filtering
    const filteredCount = ref(null as number | null);

    const fieldsWithFilter = ref(
      [] as (TableField & { filterContent: WithFilter })[]
    );

    //#endregion

    // When Fields value change, we want to redo filtering
    watch(
      () => props.fields,
      (newValue) => {
        filters.value = [];
        fieldsWithFilter.value = useAdjustedFields(newValue, props.tdClass).map(
          (field) => {
            const filter = reactive<WithFilter>({ key: field.key, filter: "" });
            if (field.filter) filters.value.push(filter);
            return { ...field, filterContent: filter };
          }
        );
      },
      { immediate: true }
    );

    watch(
      () => filters.value,
      (newValue) => {
        tablePage.value = 1;
        emit("filtersChanged", newValue);
      },
      { immediate: true, deep: true }
    );

    watch(
      () => sortBy.value,
      (newValue) => {
        emit("sortBy", newValue);
      },
      { immediate: true }
    );

    watch(
      () => mSortDesc.value,
      (newValue) => {
        emit("sortDesc", newValue);
      },
      { immediate: true }
    );

    function filtered(_: any[], count: number) {
      const hasFilter = filters.value.some((filter) => filter.filter != "");
      filteredCount.value = hasFilter ? count : null;
    }

    return {
      useNumberFormat,
      filters: computed(() =>
        filters.value.some((element) => element.filter.length > 0)
          ? filters.value
          : null
      ),
      // If specified by prop, prop overrides local item length
      totalRows: computed(() =>
        props.totalItems
          ? props.totalItems
          : filteredCount.value != null
          ? filteredCount.value
          : props.items.length
      ),
      filteredCount,
      filtered,
      fieldsWithFilter,
      fieldsWithOption: computed(() => {
        const fields = props.fields;
        if (props.options)
          fields.push({
            key: "options",
            label: "",
            filter: false,
            width: "15%",
          });

        return useAdjustedFields(fields, props.tdClass);
      }),
      tablePage,
      // If specified by prop, prop overrides table page
      mCurrentPage: computed<number>({
        get(): number {
          return props.currentPage ? props.currentPage : tablePage.value;
        },
        set(newValue: number): void {
          if (props.currentPage == null) tablePage.value = newValue;
          emit("update:currentPage", newValue);
          useScrollToElement("#pagination");
        },
      }),
      sortBy,
      mSortDesc,
      hasFilterRow: computed(() =>
        fieldsWithFilter.value.some((element) => element.filter == true)
      ),
      filter: useFilter,
      getFieldName: (field: { key: string } & BvTableField) =>
        `cell(${field.key})`,
      getHeaderName: (field: { key: string } & BvTableField) =>
        `head(${field.key})`,
      useLowercased,
      min: Math.min,
    };
  },
});

// Returns fields with specified tdClass. Also is maps fields with its width specification
function useAdjustedFields(fields: TableField[], tdClass: string) {
  return fields.map((element) => {
    return {
      ...element,
      thClass: "text-truncate",
      thStyle: element.thStyle ?? "" + `width: ${element.width}`,
      tdClass:
        element.tdClass ??
        "" + ` ${tdClass}` + (element.width ? " custom-width" : ""),
    };
  });
}
