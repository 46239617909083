






































































import IMenu from "@/interfaces/IMenu.ts";
import { FullScreenKey } from "@/symbols";
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  ref,
  watch,
} from "@vue/composition-api";
import ConfirmDeletePopup from "./ConfirmDeletePopup.vue";
import Dropdown from "./Dropdown.vue";
import Spinner from "./Spinner.vue";
import useGoToParent from "@/composables/useGoToParent";

const confirmDelete = ref(
  undefined as
    | ((name: string, confirm: string | null) => Promise<boolean>)
    | undefined
);

export function useConfirmDelete(
  name: string,
  confirm: string | null
): Promise<boolean> {
  return confirmDelete.value!(name, confirm);
}

export default defineComponent({
  components: { Spinner, Dropdown, ConfirmDeletePopup },
  inheritAttrs: false,
  name: "Sidebar",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Vent venligst ...",
    },
    padding: {
      type: String,
      default: "p-4",
    },
    title: {
      type: String,
      required: false,
    },
    allowFullscreen: {
      type: Boolean,
      required: false,
    },
    menuItems: {
      type: Array as PropType<IMenu[]>,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const show = ref(false);
    const fullscreen = ref(false);
    const confirmDeletePopup = ref<InstanceType<typeof ConfirmDeletePopup>>();

    provide(FullScreenKey, fullscreen);

    confirmDelete.value = (
      name: string,
      confirm: string | null
    ): Promise<boolean> => {
      return confirmDeletePopup.value!.confirmDelete(name, confirm);
    };

    let scrollPosition = 0;

    const onKey = (e: KeyboardEvent) => {
      if (e.code == "Escape") {
        const el = document?.querySelector("#popover") as HTMLSelectElement;
        if (!el) {
          goToParent();
        }
      }
    };

    function disableScrolling() {
      scrollPosition = window.pageYOffset;
      const $body = document.querySelector("body");
      if ($body) {
        $body.style.overflow = "hidden";
        $body.style.position = "fixed";
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = "100%";
      }
    }

    function enableScrolling() {
      const $body = document.querySelector("body");
      if ($body) {
        $body.style.removeProperty("overflow");
        $body.style.removeProperty("position");
        $body.style.removeProperty("top");
        $body.style.removeProperty("width");
        window.scrollTo(0, scrollPosition);
      }
    }

    function goToParent() {
      useGoToParent(root.$router);
    }

    onMounted(() => {
      window.addEventListener("keyup", onKey, false);
      disableScrolling();
      setTimeout(() => {
        show.value = true;
      }, 80);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keyup", onKey);
      enableScrolling();
    });

    watch(
      () => props.allowFullscreen,
      (newValue) => {
        if (newValue == false) fullscreen.value = false;
      }
    );

    return {
      confirmDeletePopup,
      fullscreen,
      show,
      goToParent,
    };
  },
});
