






































import { defineComponent, PropType } from "@vue/composition-api";
import IStep from "@/interfaces/IStep";

export default defineComponent({
  props: {
    steps: {
      type: Array as PropType<IStep[]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<IStep>,
      required: true,
    },
  },
});
