var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100"},[_c('b-table',{class:_vm.tableClass,attrs:{"primary-key":"id","thead-tr-class":"border-0","current-page":_vm.tablePage,"thead-class":_vm.hideHeader ? 'd-none' : '',"sort-by":_vm.sortBy,"sort-desc":_vm.mSortDesc,"sort-compare-locale":_vm.sortCompareLocale,"filter":_vm.filters,"selectable":_vm.selectable,"selected-variant":"","busy":_vm.isLoading,"per-page":_vm.lite ? null : _vm.perPage,"filter-function":_vm.customFilterFunction ? _vm.customFilterFunction : _vm.filter,"items":_vm.items,"striped":"","hover":_vm.selectable,"fields":_vm.fieldsWithOption,"no-local-sorting":_vm.disableLocalSort,"show-empty":"","responsive":"lg"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.mSortDesc=$event},"update:sort-desc":function($event){_vm.mSortDesc=$event},"filtered":_vm.filtered,"row-clicked":function($event){return _vm.$emit('clicked', $event)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center my-5 py-5"},[_c('Spinner')],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center",class:_vm.lite ? 'my-1' : 'my-5'},[(_vm.error)?_c('Error',{attrs:{"title":_vm.error.name,"message":_vm.error.message}}):_c('Error',{attrs:{"title":_vm.lite ? undefined : _vm.$tc('error.empty_title'),"message":_vm.$t('error.empty_message', {
              title: _vm.title
                ? _vm.useLowercased(_vm.title)
                : _vm.useLowercased(_vm.$tc('common.data')),
            }).toString()}})],1)]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center",class:_vm.lite ? 'my-1' : 'my-5'},[_c('Error',{attrs:{"title":_vm.lite ? undefined : _vm.$tc('error.emptyfiltered_title'),"message":_vm.$tc('error.emptyfiltered_message')}})],1)]},proxy:true},(_vm.hasFilterRow)?{key:"top-row",fn:function(){return [_vm._l((_vm.fieldsWithFilter),function(field){return _c('td',{key:field.key,staticClass:"position-relative",class:("" + (field.tdClass)),style:(field.thStyle)},[_c('div',{staticClass:"position-relative"},[(field.filter)?_c('b-input',{attrs:{"debounce":_vm.customFilterFunction ? 400 : 0,"placeholder":field.label,"autofocus":field.focus},model:{value:(field.filterContent.filter),callback:function ($$v) {_vm.$set(field.filterContent, "filter", $$v)},expression:"field.filterContent.filter"}}):_vm._e(),(field.filter && _vm.isBusy)?_c('span',{staticClass:"form-control-feedback align-self-center"},[_c('font-awesome-icon',{staticClass:"ml-3",attrs:{"icon":"spinner","spin":""}})],1):_vm._e()],1)])}),(_vm.options)?_c('td',{staticClass:"custom-width"}):_vm._e()]},proxy:true}:null,{key:"row-details",fn:function(data){return [_vm._t("row-details",null,null,data)]}},_vm._l((_vm.fieldsWithOption),function(field){return {key:_vm.getFieldName(field),fn:function(data){return [_vm._t(_vm.getFieldName(field),function(){return [(field.key == 'options')?_c('div',{key:("" + (data.index)),staticClass:"d-flex justify-content-end"},[_c('Dropdown',{attrs:{"id":("" + (data.index)),"passthrough":data.item,"menuItems":_vm.options}})],1):[_vm._v(" "+_vm._s(data.value)+" ")]]},null,data)]}}}),_vm._l((_vm.fieldsWithOption),function(field){return {key:_vm.getHeaderName(field),fn:function(ref){
            var label = ref.label;
            var ref_field = ref.field;
            var key = ref_field.key;
            var sortable = ref_field.sortable;
return [_vm._t(_vm.getHeaderName(field),function(){return [_vm._v(" "+_vm._s(label)+" "),(sortable)?_c('font-awesome-icon',{staticClass:"ml-3",class:_vm.sortBy == key ? 'text-info' : 'text-muted',attrs:{"icon":_vm.sortBy == key ? (_vm.mSortDesc ? 'sort-up' : 'sort-down') : 'sort'}}):_vm._e()]})]}}})],null,true)}),(!_vm.lite)?_c('div',{staticClass:"mt-auto pt-2 d-flex justify-content-between"},[(_vm.totalRows > 0)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(" "+_vm._s(_vm.$t("common.pagination", { range: ((_vm.useNumberFormat( (_vm.mCurrentPage - 1) * _vm.perPage + 1 )) + " - " + (_vm.useNumberFormat(_vm.min(_vm.mCurrentPage * _vm.perPage, _vm.totalRows)))), totalCount: _vm.useNumberFormat(_vm.totalRows), title: _vm.useLowercased(_vm.title), }))+" ")]):_vm._e(),(_vm.totalRows > _vm.perPage)?_c('b-pagination',{attrs:{"id":"pagination","total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.mCurrentPage),callback:function ($$v) {_vm.mCurrentPage=$$v},expression:"mCurrentPage"}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }