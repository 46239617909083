import IMenu from "@/interfaces/IMenu";

export default function <T>(entityName: string): IMenu<T> {
  return {
    i18nAction: "common.show",
    name: entityName,
    command: () => undefined,
    icon: "compress-alt",
  };
}
